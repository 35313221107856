import React from 'react'
import { Form, Modal, Button } from 'react-bootstrap'

const Checks = ({ currency, setCurrency, show, handleClose }) => {
    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header>
                <Modal.Title className='text-center'>
                    <h5 className='m-auto'>Please Choose a Currency</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleClose}>
                    <Form.Group>
                        <div className='text-center'>
                            <Form.Check
                                inline
                                type='radio'
                                label='Naira'
                                name='donationCurrency'
                                value='Naira'
                                checked={currency === 'Naira' ? true : false}
                                onChange={e => setCurrency(e.target.value)}
                            >
                            </Form.Check>
                            <Form.Check
                                inline
                                type='radio'
                                label='Dollar'
                                name='donationCurrency'
                                value='Dollar'
                                checked={currency === 'Dollar' ? true : false}
                                onChange={e => setCurrency(e.target.value)}
                            >
                            </Form.Check>
                            <Form.Check
                                inline
                                type='radio'
                                label='Euro'
                                name='donationCurrency'
                                value='Euro'
                                checked={currency === 'Euro' ? true : false}
                                onChange={e => setCurrency(e.target.value)}
                            >
                            </Form.Check>
                            <Form.Check
                                inline
                                type='radio'
                                label='Rand'
                                name='donationCurrency'
                                value='Rand'
                                checked={currency === 'Rand' ? true : false}
                                onChange={e => setCurrency(e.target.value)}
                            >
                            </Form.Check>
                            <Form.Check
                                inline
                                type='radio'
                                label='Pound'
                                name='donationCurrency'
                                value='Pound'
                                checked={currency === 'Pound' ? true : false}
                                onChange={e => setCurrency(e.target.value)}
                            >
                            </Form.Check>
                            <Form.Check
                                inline
                                type='radio'
                                label='CAD'
                                name='donationCurrency'
                                value='CAD'
                                checked={currency === 'CAD' ? true : false}
                                onChange={e => setCurrency(e.target.value)}
                            >
                            </Form.Check>
                        </div>
                    </Form.Group>
                    <div className="text-center">
                        <Button className='text-center' type="submit" variant="secondary">
                            Done
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default Checks
