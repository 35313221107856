import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.css';
import 'react-calendar/dist/Calendar.css'
import App from './App';

ReactDOM.render(
    <App />,
  document.getElementById('root')
);
