import axios from "axios";

const axiosInstance = axios.create({
    baseURL: 'http://localhost:5000'
})

export const joinOneDollarCampaign = async (data) => {
    return await axiosInstance.post('/api/one-dollar/', data)
}

export const getUsernames = async () => {
    return await axiosInstance.get('/api/one-dollar/usernames')
}