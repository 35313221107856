export const supportedCurrencies = [
    { label: "Argentine Peso", value: "ARS" },
    { label: "Brazilian Real", value: "BRL" },
    { label: "British Pound Sterling", value: "GBP" },
    { label: "Canadian Dollar", value: "CAD" },
    { label: "Cape Verdean Escudo", value: "CVE" },
    { label: "Chilean Peso", value: "CLP"},
    { label: "Colombian Peso", value: "COP"},
    { label: "Congolese France", value: "CDF"},
    { label: "Egyptian Pound", value: "EGP" },
    { label: "SEPA", value: "EUR" },
    { label: "Gambian Dalasi", value: "GMD" },
    { label: "Ghanaian Cedi", value: "GHS" },
    { label: "Guinean Franc", value: "GNF" },
    { label: "Kenyan Shilling", value: "KES" },
    { label: "Liberian Dollar", value: "LRD" },
    { label: "Malawian Kwacha", value: "MWK" },
    { label: "Mexican Peso", value: "MXN" },
    { label: "Moroccan Dirham", value: "MAD" },
    { label: "Mozambican Metical", value: "MZN" },
    { label: "Nigerian Naira", value: "NGN" },
    { label: "Peruvian Sol", value: "SOL" },
    { label: "Rwandan Franc", value: "RWF" },
    { label: "Sierra Leonean Leone", value: "SLL" },
    { label: "São Tomé and Príncipe dobra", value: "STD" },
    { label: "South African Rand", value: "ZAR" },
    { label: "Tanzanian Shilling", value: "TZS" },
    { label: "Ugandan Shilling", value: "UGX" },
    { label: "United States Dollar", value: "USD" },
    { label: "Central African CFA Franc BEAC", value: "XAF" },
    { label: "West African CFA Franc BCEAO", value: "XOF" },
    { label: "Zambian Kwacha (pre-2013)", value: "ZMK" },
    { label: "Zambian Kwacha", value: "ZMW" },
]

export const amountRelToNaira = [
    { curAbbr: "ARS", amount: 3.22 },
    { curAbbr: "BRL", amount: 76.72 },
    { curAbbr: "GBP", amount: 499.64 },
    { curAbbr: "CAD", amount: 322.87 },
    { curAbbr: "CVE", amount: 3.86 },
    { curAbbr: "CLP", amount: 0.45 },
    { curAbbr: "COP", amount: 0.096 },
    { curAbbr: "CDF", amount: 0.21 },
    { curAbbr: "EGP", amount: 21.94 },
    { curAbbr: "EUR", amount: 425.31 },
    { curAbbr: "GMD", amount: 7.68 },
    { curAbbr: "GHS", amount: 51.02 },
    { curAbbr: "GNF", amount: 0.048 },
    { curAbbr: "KES", amount: 3.51 },
    { curAbbr: "LRD", amount: 2.72 },
    { curAbbr: "MWK", amount: 0.40 },
    { curAbbr: "MXN", amount: 20.24 },
    { curAbbr: "MAD", amount: 40.26 },
    { curAbbr: "MZN", amount: 6.50 },
    { curAbbr: "NGN", amount: 1 },
    { curAbbr: "SOL", amount: 106.22 },
    { curAbbr: "RWF", amount: 0.40 },
    { curAbbr: "SLL", amount: 0.032 },
    { curAbbr: "STD", amount: 0.018895 },
    { curAbbr: "ZAR", amount: 24.30 },
    { curAbbr: "TZS", amount: 0.18 },
    { curAbbr: "UGX", amount: 0.11 },
    { curAbbr: "USD", amount: 415.40 },
    { curAbbr: "XAF", amount: 0.65 },
    { curAbbr: "XOF", amount: 0.65 },
    { curAbbr: "ZMK", amount: 25.25 },
    { curAbbr: "ZMW", amount: 25.25 },
]