import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import styles from './styles.module.css';
import { CountryDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
import Select from 'react-select';
import { supportedCurrencies } from "../../services/SupportedCurrencies";
import { Countries } from "../../services/Countries";
import swal from "@sweetalert/with-react";
import { submitToApi } from "../../services/Helpers";
import { closePaymentModal, useFlutterwave } from "flutterwave-react-v3";
import { validateEmail } from "../../utils/util";
import Checks from "../../components/Checks";
import { getUsernames } from "../../services/oneDollarServices";
import { useParams } from "react-router-dom";


const OneDollarDonationFormScreen = () => {

    const [pageLoading, setPageLoading] = useState(true);
    const [usernameFound, setUsernameFound] = useState(false);
    const { username } = useParams();
    const [details, setDetails] = useState({
        firstName: '',
        lastName: '',
        email: '',
        country: '',
        phone: '',
        currency: '',
        amount: '1',
    });
    const [countryString, setCountryString] = useState('');
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [show, setShow] = useState(false);
    const [currency, setCurrency] = useState('');

    useEffect(() => {

        document.title = "Donate | 1Dollar"

        getUsernames().then(res => {
            // console.log(res.data)
            setPageLoading(false);
            const usernames = res.data.map(item => item.username);
            const foundUsername = usernames.find(item => item === username);
            if (!foundUsername) return

            setUsernameFound(true);
        }).catch(err => {
            setPageLoading(false)
        })

    }, [])

    useEffect(() => {

        if (details.country.length < 1) return

        setCountryString(countryCode(details.country))

    }, [details.country])


    const handleDetailChange = (name, value, numericValue = false) => {
        const updatedValue = numericValue ?
            value.replace(/\D/g, "")
            :
            value;

        setDetails((prevDetails) => {
            return {
                ...prevDetails,
                [name]: updatedValue,
            }
        })
    }


    const countryCode = (countryName) => {
        return Countries.find(({ Code, Name }) => Name.toLowerCase() === countryName.toLowerCase())?.Code.toLowerCase()
    }

    const config = {
        public_key: "FLWPUBK-d7680be9103ea527a15b6114640bc87b-X",
        tx_ref: Date.now(),
        amount: Number(details.amount),
        currency: details.currency,
        payment_options: "card,mobilemoney,ussd",
        // specified redirect URL
        redirect_url: "https://rhapsodybible.org/biblemissions/?success=true",
        customer: {
            email: details.email,
            phonenumber: details.phone,
            name: details.firstName,
        },
        customizations: {
            title: "Rhapsody Bible",
            description: "Biblemandate Donation",
            logo: "https://rhapsodybible.orgs/wp-content/uploads/2020/03/Rhapsody-Bible-logo.png",
        },
    };

    const handleFlutterPayment = useFlutterwave(config);

    async function handleFlutterwave() {
        setBtnDisabled(true);

        const res = await submitToApi(
            "https://biblemandate.onrender.com/api/biblemissions",
            {
                ...details,
                city: '',
                referrer: "",
            }
        );

        if (res?.data.message) {
            setBtnDisabled(false);
            return swal("Error", res.data.message, "error");
        }

        handleFlutterPayment({
            callback: (response) => {
                console.log(response);
                setBtnDisabled(false);
                //  window.location.href = "/";
                closePaymentModal(); // this will close the modal programmatically
            },
            onClose: () => {
                setBtnDisabled(false);
            },
        });

    }

    const checkErrors = (action) => {
        const requiredKeys = Object.keys(requiredFieldErrors);

        const everyRequiredFieldHasValue = requiredKeys.every(key => details[key].length > 0);
        if (!everyRequiredFieldHasValue) {
            const foundMissingValIndex = requiredKeys.findIndex(key => details[key].length < 1);
            if (foundMissingValIndex === -1) return

            return swal("Info", requiredFieldErrors[`${requiredKeys[foundMissingValIndex]}`], "info");
        }

        if (!validateEmail(details.email)) return swal("Info", "Please enter a valid email", "info");

        if (action === 'now') {
            handleFlutterwave()
        }

        if (action === 'transfer') {
            setShow(true);
        }
    }

    const handleCloseCurrencyModal = (e) => {
        e.preventDefault()
        setShow(false);
    }

    if (pageLoading) return <>Please wait...</>
    if (!usernameFound) return <></>

    return <>
        <Container className={styles.wrapper}>
            <div className={styles.wrapper} style={{ pointerEvents: btnDisabled ? 'none' : 'all' }}>
                <Row className={styles.row__Item}>
                    <Checks
                        handleClose={(e) => handleCloseCurrencyModal(e)}
                        show={show}
                        setShow={setShow}
                        currency={currency}
                        setCurrency={setCurrency}
                    />

                    <Col className={styles.left__Content} md={6}>
                        <img src="https://nowthatyouarebornagain.org/sponsor/public/img/NTYABA012.png" alt="one dollar" />
                    </Col>
                    <Col className={styles.right__Content} md={6}>
                        <h3 style={{ marginBottom: '2rem' }}>Donate</h3>

                        <Row>
                            <Col md={6}>
                                <Form.Group controlId='firstName'>
                                    <Form.Control
                                        type='firstName'
                                        value={details.firstName}
                                        placeholder='First name'
                                        name="firstName"
                                        onChange={({ target }) => handleDetailChange(target.name, target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId='lastName'>
                                    <Form.Control
                                        type='lastName'
                                        value={details.lastName}
                                        placeholder='Last name'
                                        name="lastName"
                                        onChange={({ target }) => handleDetailChange(target.name, target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group controlId='email'>
                            <Form.Control
                                type='email'
                                value={details.email}
                                placeholder='Email'
                                name="email"
                                onChange={({ target }) => handleDetailChange(target.name, target.value)}
                            />
                        </Form.Group>

                        <Row>
                            <Col md={6}>
                                <CountryDropdown
                                    className="form-control"
                                    value={details.country}
                                    placeholder='Country'
                                    name="country"
                                    onChange={(val) => handleDetailChange('country', val)}
                                />
                            </Col>
                            <Col md={6}>
                                <PhoneInput
                                    country={countryString}
                                    inputStyle={{ "width": "100%" }}
                                    placeholder='Phone'
                                    value={details.phone}
                                    name="phone"
                                    onChange={(val) => handleDetailChange('phone', val)}
                                />
                            </Col>
                        </Row>

                        <Form.Group
                            controlId='currency'
                            style={{
                                marginTop: '1rem',
                                pointerEvents: 'none'
                            }}
                        >
                            <Select
                                value={{ label: 'United States Dollar', value: 'USD' }}
                                onChange={() => { }}
                                options={supportedCurrencies}
                                placeholder={"Currency"}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId='amount'>
                            <Form.Control
                                type='number'
                                value={details.amount}
                                placeholder='Amount'
                                name="amount"
                                onChange={({ target }) => handleDetailChange(target.name, target.value, true)}
                            />
                        </Form.Group>

                        <Button
                            className='px-5 button'
                            style={{
                                backgroundColor: "#EC4D0F",
                                borderColor: '#EC4D0F',
                                filter: btnDisabled ? 'brightness(0.85)' : 'brightness(1)',
                            }}
                            onClick={() => checkErrors('now')}
                            disabled={btnDisabled}
                        >
                            Donate Now
                        </Button>

                        <Button
                            className='px-5 button'
                            style={{
                                backgroundColor: "#EC4D0F",
                                borderColor: '#EC4D0F',
                                filter: btnDisabled ? 'brightness(0.85)' : 'brightness(1)',
                            }}
                            onClick={() => checkErrors('transfer')}
                            disabled={btnDisabled}
                        >
                            Donate Now with Bank Transfer
                        </Button>
                    </Col>
                </Row>
            </div>
        </Container>
    </>
}

const requiredFieldErrors = {
    firstName: 'Please enter your first name',
    lastName: 'Please enter your last name',
    email: 'Please enter your email',
    country: 'Please enter your country',
    phone: 'Please enter your phone number',
}

export default OneDollarDonationFormScreen;
