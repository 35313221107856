import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const Footer = () => {
    return (
        <footer className='footer'>
            <Container>
                <Row>
                    <Col className='text-center py-5 coll'>
                        <h5 className='h5-footer'>For more information contact us at:</h5>
                        <i className="fas fa-phone-alt i"></i> {'  '}
                        <span>United Kingdom</span> <a href="tel:+441708556604">  +441708556604</a> <br/>
                        <span>South Africa</span> <a href="tel:+27630328005">  +27630328005</a> <br/>
                        <span>Nigeria</span> <a href="tel:+2348171987339">  +2348171987339</a> <br/> <br/>

                        <i className="fas fa-envelope"></i> {' '} 
                        <span>Partnership:</span> <a href="mailto:partnership@rhapsodybible.org"> partnership@rhapsodybible.org</a> <br/>
                        <span>Enquiries:</span> <a href="mailto:info@rhapsodybible.org"> info@rhapsodybible.org</a> <br/><br/>

                        Copyright &copy; {new Date().getFullYear()}
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer
