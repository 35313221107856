import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import styles from './styles.module.css';


const LoginScreen = () => {

    const [ details, setDetails ] = useState({
        email: '',
        password: ''
    })

    useEffect(() => {
        document.title = "Login | 1Dollar"
    }, [])
    

    const handleDetailChange = (name, value) => {
        setDetails((prevDetails) => {
            return {
                ...prevDetails,
                [name]: value,
            }
        })
    }
    
    return <>
        <Container className="py-4">
            <div className={styles.wrapper}>
                <Row>
                    <Col className="my-3" md={12}>
                        <h3>Login</h3>
                        <span>View users that have used your unique payment link</span>
                        <br />
                        <br />

                        <Form.Group controlId='email'>
                            <Form.Control
                                type='email'
                                value={details.email}
                                placeholder='Email'
                                name="email"
                                onChange={({ target }) => handleDetailChange(target.name, target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId='password'>
                            <Form.Control
                                type='password'
                                value={details.password}
                                placeholder='Password'
                                name="password"
                                onChange={({ target }) => handleDetailChange(target.name, target.value)}
                            />
                        </Form.Group>

                        <Button 
                            className='px-5 button' 
                            style={{ backgroundColor: "#EC4D0F", borderColor: '#EC4D0F' }}
                            onClick={() => {}}>
                                Login
                        </Button>
                    </Col>
                </Row>
            </div>
        </Container>
    </>
}

export default LoginScreen;
